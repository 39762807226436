body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

.checkboxWidget {
  position: relative;
  padding-left: 10px;
}

.inputField {
  margin-left: 10px;
  margin-right: 10px;
}

input[type=checkbox] {
  cursor: pointer;
  font-size: 17px;
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  transform: scale(1.5);
}

input[type=checkbox]:after {
  content: " ";
  background-color: #fff;
  display: inline-block;
  color: #00BFF0;
  width: 20px !important;
  height: 20px !important;
  visibility: visible;
  border: 1px solid #FFF;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.08), 0 0 2px 0 rgba(0,0,0,0.16);
  font-size: 15px;
  padding: 2px 2px;
  float: left;
}

input[type=checkbox]:checked:after {
  content: "\2714";
  font-weight: bold;
  width: 20px;
  height: 20px;
}

.checkbox-label {
  float: left;
  position: absolute;
  top: 17px;
  left: 13px;
  font-size: 16px;
}

.admin-button.selected {
  background-color: #33baed;
  color: black;
}

.shiny {
  background: linear-gradient(to bottom, #ffffff57 10%, transparent 12%, transparent 50%, #ffffff57 100%);
}