.pageAdmin {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
}

.admin-typein-container {
    display: flex;
    flex-direction: row;
}

.admin-typein-equal {
    width: 100px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.admin-typein-equal div {
    position: absolute;
    bottom: -13px;
}

.admin-typein-buttons {
    width: 200px;
}

.admin-typein-buttons i{
    padding-left: 5px;
    cursor: pointer;
}

.admin-typein-buttons i:hover{
   color: #216087;
}


.editForm {
    margin-left: 30px;
    margin-right: 30px;
}

.jsonGroup {
    border: black 2px solid;
    margin: 5px;
    padding: 15px;
    background-color: #e4e4e4;
    font-size: 13px;
}

.jsonGroup input {
    font-size: 13px;
    height: 35px;
    margin-bottom: 5px;
}

.jsonGroup select {
    font-size: 13px;
    height: 35px;
    margin-bottom: 5px;
}

.admin-options-buttons {
    width: 390px;
}

.admin-options-buttons i{
    padding-left: 5px;
    cursor: pointer;
}

.admin-options-buttons i:hover{
    color: #216087;
}

.admin-options-buttons-bottom {
    width: 100%;
    clear: both;
}

.admin-options-buttons-bottom i{
    padding-left: 5px;
    cursor: pointer;
}

.admin-options-buttons-right {
    position: relative;
    float: right;
}

.pageAdmin .save-button {
    margin-top: 10px;
    width: 200px;
}

.admin-textfield {
    height: 200px;
}

.admin-alt-textfield {
    height: 100px;
}

.admin-displayOrder {
    background-color: lightcoral;
}

.admin-fileLoaded {
    font-weight: bold;
}

.admin-checkbox {
    float: left;
    top: 20px;
    margin-left: 10px;
}

.admin-storeResult {
    float: left;
}

.store-checkbox {
    padding-top: 5px;
    clear: both;
}

.padFileList {
    margin-top: 10px;
}

.admin-button.red {
    background-color: #ff4a45;
    color: black;
}

.numbersToWords {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.numbersToWords input {
    left: 15px;
}
