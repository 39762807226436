.loginAligner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    flex-flow: column;
}

.loginWindow {
    max-width: 50%;
    padding: 50px;
    background-color: #e0e0e0;
    border-style: solid;
    border-width: 1px;
    border-color: #0a0a0a;
    min-width: 300px;
}

.loginText {
    max-width: 50%;
    min-width: 300px;
}

.createPasswordBackdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.7);
}

.createPasswordWindow {
    width: 300px;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #0a0a0a;
    min-width: 300px;
    padding: 10px;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {

    .loginWindow {
        padding: 40px;
    }
}