/* Image Upload CSS */

.close {
    border-color: white;
    border-style: solid;
    border-radius: 43px;
    border-width: 8px;
}

.el-centered {
    margin: 0 auto;
}

#to-be-uploaded {
    padding: 0 0 30px 0;
}

.file-to-upload-info-div {
    height: 100%;
    width: 100%;
    position: relative;
    left: 0.9375rem;
    background-color: #F4FCFF;
    border-width: 1px;
    border-radius: 2px;
    margin-bottom: 5px;
}

.file-to-upload-info-div-component {
    padding: 5px;
    height: 100%;
}

.delete-button {
    position: absolute;
    margin: auto;
    top: -2px;
    right: -2px;
    z-index: 1;
}

.delete-button button {
    border-radius: 50px;
    box-shadow: black 2px 5px 2px;
}

.image-to-be-uploaded {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.button-div {
    width: 100%;
    text-align: center;
}

.pending-flag {
    color: #0393CA;
}

.uploading-flag {
    color: #ffffff;
    background-color: #258ca0;
}

.uploaded-flag {
    color: #ffffff;
    background-color: #0abd6f;
}

.error-flag {
    color: #fdfdfd;
    background-color: #8d2104;
}

.upload-flag {
    font-weight: bold;
}

.disabled-dropzone-component {
    background-color: #33BAED;
}

.disabled-dropzone-component p {
    font-weight: bold;
}

.dropzone {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.dropzone-component {
    height: 200px;
    background-color: #F4FCFF;
    border-radius: 10px;
    border-style: dashed;
    border-color: #33BAED;
    margin: auto;
}

.dropzone-div {
    width: 100%;
    height: 100%;
}

.dropzone-text {
    position: relative;
    top: 27px;
    text-align: center;
}

.not-allowed {
    color: red;
    top: 45%;
}

.allowed {
    color: green;
    top: 45%;
}

.upload-button {
    font-size: 24px !important;
    width: 100%;
    margin-top: 10px !important;
    font-weight: bold !important;
    color: white !important;
    border-radius: 17px !important;
}

.disabled-button {
    font-size: 24px !important;
    width: 100%;
    margin-top: 10px !important;
    font-weight: bold !important;
    color: white !important;
    border-radius: 17px !important;
    background-color: gray !important;
    cursor: default !important;
}


