.resizeWidget {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000007a;
    z-index: 100;
    left: 0;
    top: 0;
}

.resizeWidgetCrop {
    position: relative;
    display: block;
    width: 800px;
    height: 600px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
}

.imageToCrop {
    position: relative;
    display: block;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.croppingTool {
    position: absolute;
    top: 25px;
    bottom: 75px;
    left: 25px;
    right: 25px;
    border-style: dashed;
    background: black;
    border-width: 3px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.saveBar {
    position: absolute;
    bottom: 25px;
    height: 50px;
    left: 25px;
    right: 25px;
    background-color: #222222;
}

.admin-dropdown {
    width: 100px;
    height: 22px;
    font-size: 13px;
    padding: 0px;
    color: white;
    background-color: #393939 !important;
    margin-left: 20px;
}