.projectAligner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    flex-flow: column;
    padding-left: 30px;
    padding-right: 30px;
}

.projectWindow {
    bottom: 50px;
    padding: 50px;
    background-color: #e0e0e0;
    border-style: solid;
    border-width: 1px;
    border-color: #0a0a0a;
    overflow: scroll;
    flex-grow: 0.9;
    max-width: 62.5rem;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.searchWidget {

}

.scaleButton {
    width: 100%;
    color: white !important;
}

.projectHeader {
    width: 100%;
    height: 50px;
    background-color: #eaeaea;
    margin-bottom: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    position: relative;
    padding: 4px;
    padding-right: 19px;
}

.newProjectButton {

}

.projectWindow tr {
    cursor: pointer;
}

.projectWindow tr:hover {
    background-color: #e6f7d9;
}

.newProjectContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #00000073;
    z-index: 10;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.newProjectWindow {
    width: 80%;
    max-width: 400px;
    min-height: 300px;
    background-color: white;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    position: absolute;
}

.newProjectHeader {
    top: 0px;
    width: 100%;
    height: 42px;
    background-color: wheat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}

.icon-right {
    float: right;
}

.newProjectBody {
    position: relative;
    margin-top: 10px;
}

.bottomButton {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.bottomButton .button {
   width: 100%;
    color: white;
}

.projectListHeader {
    width: 100%;
    clear: both;
    background-color: black;
    color: white;
}

.highlightOk:active {
    color: green;
}

.highlightDelete:active {
    color: red;
}

.downloadingBlocker {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
}

.downloadingBlocker .downloadingWindow {
    width: 320px;
    min-height: 240px;
    background-color: white;
    border-radius: 5px;
    border-color: black;
    border-width: 4px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.downloadingBlocker .assignWindow {
    width: 320px;
    min-height: 240px;
    background-color: white;
    border-radius: 5px;
    border-color: black;
    border-width: 4px;
    border-style: solid;
}

.downloadingBlocker .assignWindow .groupAssigned {
    position: relative;
    margin: 2px;
    padding: 2px 10px;
    background-color: lightgreen;
    border-radius: 10px;
    float:left;
}

.downloadingBlocker .assignWindow .groupAssigned .closeButton {
    cursor: pointer;
}

.downloadingBlocker .assignWindow .groupAssigned .closeButton:hover {
    color: white;
}


.downloadingBlocker .assignWindow .title {
    width: 100%;
    height: 32px;
    background-color: #93b1ff;
    border-bottom-color: black;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    position: relative;
    padding: 0px 5px;
}

.downloadingBlocker .assignWindow .title .closeButton {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
}

.downloadingBlocker .assignWindow .title .closeButton:hover {
    color: white;
}

.downloadingBlocker .downloadingWindow .alert {
    background-color: lightpink;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

.downloadingBlocker .downloadingWindow .downloadingBar {
    width: 90%;
    height: 20px;
    background-color: lightgray;
}

.downloadingBlocker .downloadingWindow .downloadingBar .downloadingBarFill {
    height: 100%;
    background-color: green;
}

.downloadingBlocker .downloadingWindow .windowButtons {
    height: 50px;
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-bottom: 5px;
}

.downloadingBlocker .downloadingWindow .dialogFill {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
}

.downloadingBlocker .downloadingWindow .windowButtons .confirmButton{
    height: 100%;
    background-color: lightgray;
    font-size: 12px;
    color: black;
    margin-left: 5px;
    margin-right: 5px;
    flex-grow: 1;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.admin-bar {
    width: 100%;
    background-color: black;
    margin-bottom: 10px;
    padding: 5px 10px;
    color: white;
}

.admin-button {
    padding: 0 10px;
    margin: 0 10px;
    color: white;
    background-color: #393939;
    cursor: pointer;
    border: white solid 1px;
    height: 25px;
}

.admin-button-noMargin {
    padding: 0 10px;
    color: white;
    background-color: #393939;
    cursor: pointer;
    border: white solid 1px;
    height: 25px;
}

.username {
    color: yellow;
}

.logout {
    margin-left: 10px;
    color: #b8b8b8;
}

.projectWindow .hasChanges {
    background-color: #ffc2c2;
    border: solid red 1px;
}

.clearHeight {
    height: auto;
}

@media screen and (max-width: 600px) {
    .projectWindow {
        padding: 10px;
    }

    .projectWindow table {
        font-size: 11px;
        table-layout: fixed;
        word-wrap: break-word;
    }

    .projectWindow table tr td {
        padding: 3px;
    }

    .projectWindow table tr td:first-child {
        width: 24px !important;
    }

    .projectWindow table tr td:nth-child(n+6) {
        display: none;
    }

    .projectAligner {
        padding-left: 10px;
        padding-right: 10px;
    }
}