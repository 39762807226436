.userManager {
    display: block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
}

.userManagerUsers {
    float: left;
    width: 50%;
    position: relative;
}

.userManagerGroups {
    float: left;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.userManagerTitle {
    height: 88px;
    border-bottom: solid 1px lightgray;
}

.userManagerItemHeader {
    background-color: lightgrey;
    border-bottom: solid 1px darkgrey;
}

.titleText {
    font-size: 11px;
}

.userManagerItem {
    display: flex;
    flex-flow: row;
}

.userRow {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row;
    flex-flow: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background-color: cornflowerblue;
    font-size: 12px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px white solid;
}

.growHide {
    flex-grow: 1;
    flex-wrap: nowrap;
    overflow: hidden;
}

.userManagerItem .userTitle {
    width: 150px;
}

.userManagerOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.71);
    z-index: 9;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}

.closeAssignGroupButton {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: red;
    color: white;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
}

.closeAssignGroupButton:hover {
    background-color: #bc1300;
    color: #bebebe;
}

.assignGroupToUser {
    width: 400px;
    height: 230px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.assignedGroups {
    height: 100px;
    overflow: scroll;
    background-color: lightblue;
    font-size: 12px;
}

.assignedName {
    float: left;
    padding-right: 4px;
}

.creatingNewUserWindow {
    width: 400px;
    height: 250px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.userManagerActive {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.70);
    z-index: 99;
    display: flex;
    flex-flow: column;
    padding-top: 150px;
    align-items: center;
    font-size: 50px;
}

.userManagerActive i{
    color: white;
}