/* Page buttons */
.page-button {
    width: 25px;
    height: 25px;
    background-color: white;
    padding: 4px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
    text-align: center;
    margin-left: 0;
    font-size: 12px;
}

.active-page {
    background-color: yellow;
}

.page-nav-button {
    display: block;
    width: 115px;
    height: 30px;
    text-align: center;
    background-color: #2ca6cb;
    cursor: pointer;
    margin: 10px;
    margin-left: 0;
    border-radius: 4px;
    font-size: 13px;
    color: white;
    float: left;
}