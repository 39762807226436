.report-title-bar {
    height: 34px;
    background-color: black;
    color: white;
    position: relative;
    font-size: 15px;
    padding-top: 7px;
}

.report-title-bar .title {
    text-align: center;
    position: absolute;
    width: 100%;
}

.report-title-bar .back-button{
    position: relative;
    cursor: pointer;
    float: left;
    margin-left: 10px; margin-right: 10px;
}

.report-title-bar .save-button{
    position: relative;
    cursor: pointer;
    float: left;
    margin-left: 10px; margin-right: 10px;
}

.report-title-bar .download-button{
    position: relative;
    cursor: pointer;
    float: right;
    margin-left: 10px; margin-right: 10px;
}

.report-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 215.9mm;
    border: solid 2px lightgrey;
}

.textarea {
    margin: 10px;
}

.image_50_percent {
    width: 50%
}

.image_100_percent {
    width: 90%;
}

.admin-type-select {
    background-color: #b9b9b9;
    border-bottom: solid darkgray 1px;
}

.admin-type-select .select-button {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.admin-type-select .select-button :hover{
    text-decoration: underline;
}

.admin-type-select .selected {
    color: #952d32;
}

.reportGenerateBlocker {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-flow: column;
}