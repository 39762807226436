.pageForm {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
}

.blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000085;
    z-index: 20;
    cursor: not-allowed;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.blocker .blockerWindow {
    width: 320px;
    height: 240px;
    background-color: white;
    border-radius: 5px;
    border-color: black;
    border-width: 4px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blocker .blockerWindow .windowButtons {
    height: 50px;
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-bottom: 5px;
}

.blocker .blockerWindow .dialogFill {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
}

.blocker .blockerWindow .confirmButton{
    height: 100%;
    background-color: lightgray;
    font-size: 12px;
    color: black;
    margin-left: 5px;
    margin-right: 5px;
    flex-grow: 1;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    display: flex;
}

.blocker .blockerWindow .confirmButton:active {
    background-color: lightblue;
}

.pageForm .header {
    background-image: url(/img/pool-water-background_2.png);
    color: white;
    background-size: cover;
    min-height: 75px;
    padding-left: 30px;
    text-shadow: black 3px 3px;
}

.pageForm .pageContent {
    flex-grow: 1;
    background-color: #83dcfc;
    background-image: linear-gradient(#66deff, #0386e0);
    overflow: scroll;
}

.pageForm .innerPageContent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 75px;
}

.selectPageBarContainer {
    background-color: black;
    border-bottom-style: solid;
    border-bottom-color: white;
    border-bottom-width: 3px;
    min-width: 180px;
}

.selectPageBar {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    font-size: 16px;
    display: flex;
    flex-flow: column;
}

.selectPageBar .tabItem {
    text-align: center;
    border-bottom-style: solid;
    border-bottom-color: #ffffff;
    height: inherit;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selectPageBar .tabItem.divider {
    height: 50px;
    background-color: #777777;
}

.selectPageBar .tabItem:hover {
    color: grey;
}

.selectPageBar .tabItem.selected {
    border-bottom-style: solid;
    border-bottom-color: #83dcfc;
    border-bottom-width: 2px;
    background-color: #83dcfc;
    color: black;
}

.selectPageBar .tabItem.generateReport {
    right: 0px;
    position: absolute;
    padding-top: 10px;
}

/* Uploaded Images */

.uploaded-container {
    position: relative;
}

.uploaded-image-div-outer {
    height: 190px;
    padding: 5px;
    position: relative;
}

.uploaded-image-div-inner {
    background-color: #a0a4d2;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    border-color: white;
    border-style: solid;
    border-width: 4px;
}

.uploaded-image-div-inner-image {
    border-radius: 2px;
    height: 100%;
    text-align: center;
}

.uploaded-image {
    display: inline;
    max-height: 100%;
}

.uploaded-modal-image {
    display: inline;
    max-height: 300px;
}

.uploaded-modal-container {
    position: relative;
    width: 100%;
    text-align: center;
}

.uploaded-image-div-inner-delete-div {
    height: 40%;
}

.uploaded-image-delete-button {
    position: relative;
    top: 10px;
    background-color: yellow;
}

.pageTitleContainer {
    display: block;
    width: 100%;
    background-color: black;
    position: relative;
    font-size: 20px;
    color: white;
}

.pageTitleTitle {
    color: white;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    user-select: none;
    pointer-events: none;
}

.notes {
    cursor: pointer;
    left: 17px;
    position: relative;
    display: inline;
}

.notes:hover {
    text-decoration: underline;
    color: yellow;
}

.openNoteBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.29);
    position: absolute;
    flex-direction: column;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.openNote {
    width: 80%;
    height: 80%;
    border-radius: 10px;
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.openNote .notesHeader {
    height: 30px;
    background-color: #12dcff;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    display: flex;
}

.openNote .notesFooter {
    height: 60px;
    background-color: #000000;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    flex-direction: row-reverse;
    vertical-align: middle;
    align-items: center;
    padding-top: 14px;
    padding-right: 14px;
}

.openNote .notesHeader .notesTitle {
    flex-grow: 1;
    text-align: center;
}

.openNote .notesHeader .notesIcon {
    padding-left: 5px;
    cursor: pointer;
}

.openNote .notesBody {
    flex-grow: 1;
    position: relative;
}

.openNote .notesBody .notesText {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.openNote .notesBody textarea {
    width: 100%;
    height: 100%;
}

.openNote .notesFooter .button {
    float: right;
    width: 90px;
}

.link {
    text-decoration: underline;
}

.unsynched_changes {
    position: absolute;
    top: 56px;
    left: 0;
    padding-left: 2px;
    color: white;
    background-color: red;
    width: 100%;
    text-align: right;
    font-size: 13px;
    text-shadow: black 1px 1px;
    z-index: 99;
}

.unsynched_changes span {
    margin-right: 10px;
}

.groupContainer {
    background-color: lightcoral;
    margin-bottom: 5px;
    margin-top: 5px;
    border: solid white 6px;
}

.groupLabel {
    font-weight: bold;
    border-bottom: solid white 1px;
}

.smallgroup {
    border: none;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #dad9d9;
}

.special {
    background-color: #668aa4;
}

.special:hover {
    color: yellow !important;
}

.inputField {
    position: relative;
}

.react-calendar {
    position: absolute;
}

.login-window {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.51);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginMessage {
    width: 50%;
    padding: 10px;
    position: absolute;
    background-color: lightgrey;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 15px;
    border: #888484 solid 2px;
    min-width: 280px;
}

.loginMessage .error-message {
    text-align: center;
    font-size: 14px;
    padding: 5px;
    background-color: darkgray;
    border-radius: 8px;
    border: #888484 solid 2px;
}

.loginMessage div {
    width: 100%;
}

.loginMessage .admin-button {
    margin: 0;
}

.hamburger {
    display: none;
    margin-left: 10px;
}

.closeMenuButton {
    display: none;
}

@media screen and (max-width: 600px) {
    .innerPageContent {
        display: block !important;
    }

    .selectPageBarContainer {
        position: fixed;
        z-index: 999;
        height: 100%;
        left: -180px;
        top: 0px;
    }

    .selectPageBarContainer.menuOpen {
        left: 0px;
    }

    .closeMenuButton {
        display: none;
    }

    .closeMenuButton.menuOpen {
        position: absolute;
        right: -60px;
        font-size: 35px;
        background-color: black;
        color: white;
        border-radius: 40px;
        width: 55px;
        height: 55px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-flow: column;
    }

    .hamburger {
        display: inline;
    }

    .pageForm .pageContent {
        height: 100%;
    }

    .pageForm .pageContent .pageContentRow {
        margin-left: 25px;
        margin-right: 25px;
    }

    .pageTitleTitle {
        position: relative;
        background-color: #464646;
    }

    .pageForm .header {
        padding-top: 20px;
        text-shadow: black 2px 2px;
        width: 100%;
        text-align: center;
        padding-left: 0px;
    }

    .pageForm .header h1 {
        font-weight: bold;
    }
}