
/* Uploaded Images */
.imageWidget {
    position: static;
}

.uploaded-container {
    width: 100%;
    position: relative;
    left: 0.9375rem;
}

.uploaded-image-div-outer {
    height: 190px;
    padding: 5px;
}

.uploaded-image-div-inner {
    background-color: #B3E9FE;
    border-radius: 2px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.uploaded-image-div-inner-image {
    border-radius: 2px;
    width: 100%;
    height: 60%;
    text-align: center;
}

.uploaded-image {
    display: inline;
    max-height: 100%;
}

.uploaded-image-div-inner-delete-div {
    height: 40%;
}

.uploaded-image-delete-button {
    position: relative;
    top: 10px;
    background-color: yellow;
}

.centered-text {
    text-align: center;
    margin: auto;
}

/* Image tagging */

.tags-box {
    max-height: 120px;
    padding: 0px 20px 0px 20px;
    margin-bottom: 10px;
    overflow: auto;
}

.modal-image {
    max-height: 100%;
    max-width: 100%;
}


.imageContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #00000073;
    z-index: 10;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}


.imageWindow {
    width: 80%;
    max-width: 400px;
    min-height: 300px;
    background-color: white;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    position: absolute;
}

.imageHeader {
    top: 0px;
    width: 100%;
    height: 42px;
    background-color: wheat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}

.previous-image {
    position: absolute;
    left: -50px;
    top: 50%;
    font-size: 50px;
    margin-top: -25px;
    color: #FFC107;
    cursor: pointer;
}

.next-image {
    position: absolute;
    right: -50px;
    top: 50%;
    font-size: 50px;
    margin-top: -25px;
    color: #FFC107;
    cursor: pointer;
}

.previous-image:hover {
    color: #ffffff;
}

.next-image:hover {
    color: #ffffff;
}

.uploaded-modal-container {
    margin-top: 5px;
}

.uploaded-modal-image {
    cursor: pointer;
    border: solid black 2px;
}

.uploaded-modal-image:hover {
    border: dashed red 2px;
}